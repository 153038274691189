<template>
    <v-container fluid class="mx-0 px-0 my-0 py-0">
        <v-row class="team-banner secondary-font" :style="`background: linear-gradient(160deg, ${match.team1Score > match.team2Score ? (match.team1?.color ? match.team1?.color : 'orange') : '#bbb7b6'} 50%, ${match.team2Score > match.team1Score ? (match.team2?.color ? match.team2?.color : 'orange') : '#bbb7b6'} 50%);`" justify="center" align="center">
            <v-col cols="4" md="4" class="pb-0 mb-0 clickable" @click.stop="goToTeam(match.team1)">
                <v-img
                    :src="`${assetsUrl}/${match.team1?.logo}`"
                    height="180px"
                    contain
                />
            </v-col>
            <v-col cols="4" md="2" align="center" class="score-dashboard rounded-lg">
                <v-row align="center" justify="center" class="my-0 mx-0 px-0 py-0">
                    <v-col
                        cols="5"
                        :class="match.team1Score > match.team2Score ? 'winning-score' : ''"
                        class="mx-0 px-0"
                        align="end"
                    >
                        <span class="white--text font-weight-bold mx-0 px-0 score-text" :class="!$vuetify.breakpoint.mobile ? 'text-h2' : 'text-h4'">{{ match.team1Score }}</span>
                    </v-col>
                    <v-col cols="2" class="mx-0 px-0" align="center">
                        <v-divider
                            vertical
                            color="white"
                            class="custom-divider"
                        />
                    </v-col>
                    <v-col
                        cols="5"
                        :class="match.team2Score > match.team1Score ? 'winning-score' : ''"
                        class="mx-0 px-0"
                        align="start"
                    >
                        <span class="white--text font-weight-bold mx-0 px-0 score-text" :class="!$vuetify.breakpoint.mobile ? 'text-h2' : 'text-h4'">{{ match.team2Score }}</span>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="4" md="4" class="pb-0 mb-0 clickable" @click.stop="goToTeam(match.team2)">
                <v-img
                    v-if="match.team2.logo"
                    :src="`${assetsUrl}/${match.team2?.logo}`"
                    height="180px"
                    contain
                />
                <v-icon v-else :style="$vuetify.breakpoint.mobile ? 'font-size: 80px' : 'font-size: 120px'" class="orange--text"> mdi-basketball </v-icon>
            </v-col>
            <v-col cols="12" align="center" class="my-0 py-0 text-subtitle-1 secondary-font">
                <span> {{ match.league.title }}, </span>
                <span> Round {{ match.round }}, </span>
                <span>
                    {{ match.cup? 'Κύπελλο' : (match.phase === 'regular_season' ? 'Regular Season' : (match.phase === 'placements' ? 'Προκριματικά' : 'Play Offs')) }}
                </span>
            </v-col>
            <v-col cols="12" align="center" class="text-subtitle-1 secondary-font py-0 my-0 pb-4">
                {{ getDate(match.matchDatetime) }}
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import momentTimezone from 'moment-timezone'
    export default {
        props: {
            match: {
                type: Object,
                default: null
            }
        },
        data() {
            return {
                team: {},
                assetsUrl: process.env.VUE_APP_GOOGLE_STORAGE_API
            }
        },
        methods: {
            getDate(date) {
                return momentTimezone(date).tz('Europe/Athens').locale('el-GR').format('dddd D/M/y, HH:mm').toString()
            },
            goToTeam(team) {
                this.$router.push({ name: 'Team', params: { id: team.id } })
            }
        }
    }
</script>

<style scoped>
.score-text {
    font-size: 4rem;
}

.winning-score {
    background: linear-gradient(#00f7d1, #00ec80);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.score-dashboard {
    background-color: #00000091;
}

.team-name {
    font-size: 2.2rem;
    font-weight: 800;
}

.win-lose-score {
    font-size: 2.2rem;
    font-weight: 800;
}

.win-lose-text {
    font-size: 1rem;
}

.custom-divider {
    border-width: 1px;
    height: 40px;
}

.team-banner {
    color: white;
    font-size: 1.5rem;
    background: linear-gradient(160deg, orange 50%, #bbb7b6 50%);
}
</style>
