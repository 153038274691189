<template>
    <v-container fluid class="px-0 mx-0">
        <v-row>
            <v-col cols="12">
                <v-chip-group
                    v-model="option"
                    column
                    mandatory
                >
                    <v-chip
                        v-for="(item, index) in [match.team1.name, match.team2.name]"
                        :key="index"
                        filter
                        filter-icon="mdi-basketball"
                        active-class="selected-chip custom-orange"
                        color="white"
                        large
                        mandatory
                        class="text-h5 font-weight-bold"
                    >
                        {{ item }}
                    </v-chip>
                </v-chip-group>
            </v-col>
            <v-col cols="12" class="px-0 mx-0">
                <template>
                    <v-data-table
                        :headers="headers"
                        :items="option ? match.team2.players.filter((player) => player.active) : match.team1.players.filter((player) => player.active)"
                        class="elevation-1 rounded-lg py-3 px-3 text-center"
                        hide-default-footer
                        disable-sort
                        :mobile-breakpoint="0"
                        :items-per-page="-1"
                    >
                        <template v-slot:item.player="{ item }">
                            <span v-if="!item.number" class="font-weight-bold"> {{ `${item.name} ${item.surname}` }} </span>
                            <span v-else> {{ item.player }} </span>
                        </template>
                        <template v-slot:item.points="{ item }">
                            <span class="font-weight-bold"> {{ item.stats[0]?.points }} </span>
                        </template>
                        <template v-slot:item.two_points_percentage="{ item }">
                            <span class="font-weight-bold"> {{ `${((item.stats[0]?.twoPointsMade ? item.stats[0]?.twoPointsMade : 0) / (item.stats[0]?.twoPointAttempts ? item.stats[0]?.twoPointAttempts : 1) * 100).toFixed(1)}% (${item.stats[0]?.twoPointsMade ? item.stats[0]?.twoPointsMade : 0} / ${item.stats[0]?.twoPointAttempts ? item.stats[0]?.twoPointAttempts : 0})` }} </span>
                        </template>
                        <template v-slot:item.three_points_percentage="{ item }">
                            <span class="font-weight-bold"> {{ `${((item.stats[0]?.threePointsMade ? item.stats[0]?.threePointsMade : 0) / (item.stats[0]?.threePointAttempts ? item.stats[0]?.threePointAttempts : 1) * 100).toFixed(1)}% (${item.stats[0]?.threePointsMade ? item.stats[0]?.threePointsMade : 0} / ${item.stats[0]?.threePointAttempts ? item.stats[0]?.threePointAttempts : 0})` }} </span>
                        </template>
                        <template v-slot:item.free_throw_percentage="{ item }">
                            <span class="font-weight-bold"> {{ `${((item.stats[0]?.freeThrowsMade ? item.stats[0]?.freeThrowsMade : 0) / (item.stats[0]?.freeThrowAttempts ? item.stats[0]?.freeThrowAttempts : 1) * 100).toFixed(1)}% (${item.stats[0]?.freeThrowsMade ? item.stats[0]?.freeThrowsMade : 0} / ${item.stats[0]?.freeThrowAttempts ? item.stats[0]?.freeThrowAttempts : 0})` }} </span>
                        </template>
                        <template v-slot:item.offensiveRebounds="{ item }">
                            <span class="font-weight-bold"> {{ item.stats[0]?.offensiveRebounds }} </span>
                        </template>
                        <template v-slot:item.defensiveRebounds="{ item }">
                            <span class="font-weight-bold"> {{ item.stats[0]?.defensiveRebounds }} </span>
                        </template>
                        <template v-slot:item.rebounds="{ item }">
                            <span class="font-weight-bold"> {{ item.stats[0]?.rebounds }} </span>
                        </template>
                        <template v-slot:item.assists="{ item }">
                            <span class="font-weight-bold"> {{ item.stats[0]?.assists }} </span>
                        </template>
                        <template v-slot:item.steals="{ item }">
                            <span class="font-weight-bold"> {{ item.stats[0]?.steals }} </span>
                        </template>
                        <template v-slot:item.turnovers="{ item }">
                            <span class="font-weight-bold"> {{ item.stats[0]?.turnovers }} </span>
                        </template>
                        <template v-slot:item.blockedShots="{ item }">
                            <span class="font-weight-bold"> {{ item.stats[0]?.blockedShots }} </span>
                        </template>
                        <template v-slot:item.personalFouls="{ item }">
                            <span class="font-weight-bold"> {{ item.stats[0]?.personalFouls }} </span>
                        </template>
                    </v-data-table>
                </template>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        props: {
            id: {
                type: Number,
                default: null
            },
            match: {
                type: Object,
                default: null
            }
        },
        data() {
            return {
                option: 0,
                team: 'Veterans BC',
                year: '2022-2023',
                phase: 'Regular Season',
                round: 'Round 1',
                headers: [
                    { text: 'Player', value: 'player', width: '200px', align: 'start' },
                    {
                        text: '# Number',
                        align: 'center',
                        value: 'jersey',
                        width: '50px'
                    },
                    { text: 'Pts', value: 'points', align: 'center' },
                    { text: '2P%', value: 'two_points_percentage', width: '170px', align: 'center' },
                    { text: '3P%', value: 'three_points_percentage', width: '170px', align: 'center' },
                    { text: 'FT%', value: 'free_throw_percentage', width: '170px', align: 'center' },
                    { text: 'OR', value: 'offensiveRebounds', align: 'center' },
                    { text: 'DR', value: 'defensiveRebounds', align: 'center' },
                    { text: 'TR', value: 'rebounds', align: 'center' },
                    { text: 'AST', value: 'assists', align: 'center' },
                    { text: 'STL', value: 'steals', align: 'center' },
                    { text: 'TO', value: 'turnovers', align: 'center' },
                    { text: 'BLK', value: 'blockedShots', align: 'center' },
                    { text: 'FC', value: 'personalFouls', align: 'center' }
                ]
            }
        },
        created() {
        }
    }
</script>

<style scoped>
.text-h5 {
    font-size: 1.5rem !important;
    font-weight: 400;
    letter-spacing: normal !important;
    font-family: Arial, sans-serif !important;
}

.v-chip::before {
    background-color: white !important;
}

.selected-chip {
    color: white;
}
</style>
