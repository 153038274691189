<template>
    <v-container fluid no-gutters>
        <v-row justify="center">
            <v-col cols="12" md="10" class="px-0 mx-0">
                <mvp :player="player" />
                <v-card class="rounded-lg px-1">
                    <v-row v-for="(stat, index) in stats" :key="index" justify="center" align="center" class="bottom-border">
                        <v-col cols="4" align="start" class="clickable" @click="$router.push({path:`/Player/${stat.team1.player.id}`})">
                            <v-row align="center">
                                <v-col cols="12" md="4" align="center">
                                    <img
                                        v-if="stat.team1.player?.image"
                                        :width="$vuetify.breakpoint.mobile? '70px' : '80px'"
                                        :src="`${assetsUrl}/${stat.team1.player?.image}`"
                                        @error="stat.team1.player.image = null"
                                    >
                                    <v-icon v-else x-large>
                                        mdi-basketball
                                    </v-icon>
                                </v-col>
                                <v-col cols="12" md="8" align="center">
                                    <span
                                        v-if="stat.team1.player"
                                        class="font-weight-bold secondary-font"
                                        :class="$vuetify.breakpoint.mobile? 'caption' : 'subtitle-2'"
                                    >
                                        {{ `${stat.team1.player?.name} ${stat.team1.player?.surname}` }}
                                    </span>
                                    <v-icon v-else x-large>
                                        mdi-minus
                                    </v-icon>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="4" align="center">
                            <v-row align="center" justify="center">
                                <v-col cols="12" class="mx-0 px-0">
                                    <span class="font-weight-bold secondary-font" :class="!$vuetify.breakpoint.mobile? 'text-h6' : 'subtitle-1'">{{ stat.value.includes('percentage') ? (stat.team1[stat.value] * 100).toFixed(0) : stat.team1[stat.value] }}</span>
                                    <span class="mx-1 font-weight-bold custom-orange-text" style="font-family: Arial, sans-serif !important;" :class="!$vuetify.breakpoint.mobile? 'subtitle-1' : 'subtitle-2'">{{ stat.text }}</span>
                                    <span class="font-weight-bold secondary-font" :class="!$vuetify.breakpoint.mobile? 'text-h6' : 'subtitle-1'">{{ stat.value.includes('percentage') ? (stat.team2[stat.value] * 100).toFixed(0) : stat.team2[stat.value] }}</span>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="4" align="end" class="clickable" @click="$router.push({path:`/Player/${stat.team2.player.id}`})">
                            <v-row align="center">
                                <v-col v-if="!$vuetify.breakpoint.mobile" md="8" align="center">
                                    <span v-if="stat.team2.player" class="font-weight-bold secondary-font" :class="$vuetify.breakpoint.mobile? 'caption' : 'subtitle-2'">{{ `${stat.team2.player?.name} ${stat.team2.player?.surname}` }}</span>
                                    <v-icon v-else x-large>
                                        mdi-minus
                                    </v-icon>
                                </v-col>
                                <v-col cols="12" md="4" align="center">
                                    <img
                                        v-if="stat.team2.player?.image"
                                        :width="$vuetify.breakpoint.mobile? '70px' : '80px'"
                                        :src="`${assetsUrl}/${stat.team2.player?.image}`"
                                        @error="stat.team2.player.image = null"
                                    >
                                    <v-icon v-else x-large>
                                        mdi-basketball
                                    </v-icon>
                                </v-col>
                                <v-col v-if="$vuetify.breakpoint.mobile" cols="12" align="center">
                                    <span v-if="stat.team2.player" class="font-weight-bold secondary-font" :class="$vuetify.breakpoint.mobile? 'caption' : 'subtitle-2'">{{ `${stat.team2.player?.name} ${stat.team2.player?.surname}` }}</span>
                                    <v-icon v-else x-large>
                                        mdi-minus
                                    </v-icon>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <match-stats class="mt-5" :class="$vuetify.breakpoint.mobile ? '' : 'px-16'" :match="match" />
    </v-container>
</template>

<script>
    import MatchStats from '@/components/MatchStats'
    import Mvp from '@/components/Mvp'
    export default {
        components: { MatchStats, Mvp },
        props: {
            match: {
                type: Object,
                default: null
            }
        },
        data() {
            return {
                stats: [
                    { text: 'Pts', value: 'points', team1: { player: null, points: 0 }, team2: { player: null, points: 0 } },
                    { text: '2P%', value: 'two_points_percentage', team1: { player: null, two_points_percentage: 0 }, team2: { player: null, two_points_percentage: 0 } },
                    { text: '3P%', value: 'three_points_percentage', team1: { player: null, three_points_percentage: 0 }, team2: { player: null, three_points_percentage: 0 } },
                    { text: 'FT%', value: 'free_throw_percentage', team1: { player: null, free_throw_percentage: 0 }, team2: { player: null, free_throw_percentage: 0 } },
                    { text: 'OR', value: 'offensiveRebounds', team1: { player: null, offensiveRebounds: 0 }, team2: { player: null, offensiveRebounds: 0 } },
                    { text: 'DR', value: 'defensiveRebounds', team1: { player: null, defensiveRebounds: 0 }, team2: { player: null, defensiveRebounds: 0 } },
                    { text: 'TR', value: 'rebounds', team1: { player: null, rebounds: 0 }, team2: { player: null, rebounds: 0 } },
                    { text: 'AST', value: 'assists', team1: { player: null, assists: 0 }, team2: { player: null, assists: 0 } },
                    { text: 'STL', value: 'steals', team1: { player: null, steals: 0 }, team2: { player: null, steals: 0 } },
                    { text: 'TO', value: 'turnovers', team1: { player: null, turnovers: 0 }, team2: { player: null, turnovers: 0 } },
                    { text: 'BLK', value: 'blockedShots', team1: { player: null, blockedShots: 0 }, team2: { player: null, blockedShots: 0 } },
                    { text: 'FC', value: 'personalFouls', team1: { player: null, personalFouls: 0 }, team2: { player: null, personalFouls: 0 } }
                ],
                assetsUrl: process.env.VUE_APP_GOOGLE_STORAGE_API,
                player: { stats: [{ pir: 0 }] }
            }
        },
        watch: {
            match: {
                handler() {
                    this.getMaxValues()
                    this.getMvp()
                }
            }
        },
        created() {
            this.getMaxValues()
            this.getMvp()
        },
        methods: {
            getMvp() {
                if (this.match.team1Score > this.match.team2Score) {
                    this.match.team1.players.forEach((player) => {
                        if (player.stats[0]) {
                            if (this.player.stats[0].pir < player.stats[0].pir) {
                                this.player = player
                            }
                        }
                    })
                } else {
                    this.match.team2.players.forEach((player) => {
                        if (player.stats[0]) {
                            if (this.player.stats[0].pir < player.stats[0].pir) {
                                this.player = player
                            }
                        }
                    })
                }
            },
            getMaxValues() {
                this.stats.forEach((stat) => {
                    this.match.team1.players.forEach((player) => {
                        if (player.stats.length > 0) {
                            player.stats[0].pir = (player.stats[0].points + player.stats[0].rebounds + player.stats[0].assists + player.stats[0].steals + player.stats[0].blockedShots) -
                                ((player.stats[0].twoPointAttempts + player.stats[0].threePointAttempts - player.stats[0].twoPointsMade - player.stats[0].threePointsMade) +
                                    (player.stats[0].freeThrowAttempts - player.stats[0].freeThrowsMade) + player.stats[0].turnovers + player.stats[0].personalFouls + player.stats[0].technicalFouls)
                            if (stat.value === 'two_points_percentage') {
                                if (stat.team1[stat.value] < (player.stats[0].twoPointsMade / player.stats[0].twoPointAttempts)) {
                                    stat.team1.player = player
                                    stat.team1[stat.value] = player.stats[0].twoPointsMade / player.stats[0].twoPointAttempts
                                }
                            } else if (stat.value === 'three_points_percentage') {
                                if (stat.team1[stat.value] < (player.stats[0].threePointsMade / player.stats[0].threePointAttempts)) {
                                    stat.team1.player = player
                                    stat.team1[stat.value] = player.stats[0].threePointsMade / player.stats[0].threePointAttempts
                                }
                            } else if (stat.value === 'free_throw_percentage') {
                                if (stat.team1[stat.value] < (player.stats[0].freeThrowsMade / player.stats[0].freeThrowAttempts)) {
                                    stat.team1.player = player
                                    stat.team1[stat.value] = player.stats[0].freeThrowsMade / player.stats[0].freeThrowAttempts
                                }
                            } else {
                                if (stat.team1[stat.value] < player.stats[0][stat.value]) {
                                    stat.team1.player = player
                                    stat.team1[stat.value] = player.stats[0][stat.value]
                                }
                            }
                        }
                    })
                    this.match.team2.players.forEach((player) => {
                        if (player.stats.length > 0) {
                            player.stats[0].pir = (player.stats[0].points + player.stats[0].rebounds + player.stats[0].assists + player.stats[0].steals + player.stats[0].blockedShots) -
                                ((player.stats[0].twoPointAttempts + player.stats[0].threePointAttempts - player.stats[0].twoPointsMade - player.stats[0].threePointsMade) +
                                    (player.stats[0].freeThrowAttempts - player.stats[0].freeThrowsMade) + player.stats[0].turnovers + player.stats[0].personalFouls + player.stats[0].technicalFouls)
                            if (stat.value === 'two_points_percentage') {
                                if (stat.team2[stat.value] < (player.stats[0].twoPointsMade / player.stats[0].twoPointAttempts)) {
                                    stat.team2.player = player
                                    stat.team2[stat.value] = player.stats[0].twoPointsMade / player.stats[0].twoPointAttempts
                                }
                            } else if (stat.value === 'three_points_percentage') {
                                if (stat.team2[stat.value] < (player.stats[0].threePointsMade / player.stats[0].threePointAttempts)) {
                                    stat.team2.player = player
                                    stat.team2[stat.value] = player.stats[0].threePointsMade / player.stats[0].threePointAttempts
                                }
                            } else if (stat.value === 'free_throw_percentage') {
                                if (stat.team2[stat.value] < (player.stats[0].freeThrowsMade / player.stats[0].freeThrowAttempts)) {
                                    stat.team2.player = player
                                    stat.team2[stat.value] = player.stats[0].freeThrowsMade / player.stats[0].freeThrowAttempts
                                }
                            } else {
                                if (stat.team2[stat.value] < player.stats[0][stat.value]) {
                                    stat.team2.player = player
                                    stat.team2[stat.value] = player.stats[0][stat.value]
                                }
                            }
                        }
                    })
                })
            }
        }
    }
</script>

<style>
    .v-tabs:not(.v-tabs--vertical).v-tabs--right > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes) .v-slide-group__next,
    .v-tabs:not(.v-tabs--vertical):not(.v-tabs--right) > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes) .v-slide-group__prev {
        display: none;
    }
</style>

<style scoped>
    .text-h6 {
        font-size: 1.25rem !important;
        letter-spacing: 0.0125em !important;
        line-height: 2rem;
    }

    .bottom-border {
        border-bottom: 1px solid rgb(231, 225, 225);
    }
</style>
